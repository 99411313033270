import { createStore } from 'redux';
function openid (state = undefined, action) {
    if (action.type === 'new')
        return action.val
    else
        return state
}

const store = createStore(openid);
export default store
//store.getState()

 

 