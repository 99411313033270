import React from 'react';
import '../Common/common.scss'
import privacy1 from '../../assets/privacy1.png'
import privacy2 from '../../assets/privacy2.png'
import privacy3 from '../../assets/privacy3.png'

class Privacy extends React.Component {
    render () {
        return (
            <div>
                <img className='statuteIMG' src={privacy1} alt="" />
                <img className='statuteIMG' src={privacy2} alt="" />
                <img className='statuteIMG' src={privacy3} alt="" />
            </div>
        )
    }
}
export default Privacy;