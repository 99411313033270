import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import BindProduction from './views/BindProduction/BindProduction.js'
import MyProduction from './views/MyProduction/MyProduction.js'
import AfterSales from './views/AfterSales/AfterSales.js'
import Privacy from './views/Statute/privacy.js'
import UserRules from './views/Statute/userrules.js'
import Insurancepolicy from './views/Insurancepolicy/Insurancepolicy.js'
import guardianproject from './views/guardianproject/guardianproject.js'
import guardianhome from './views/guardianhome/guardianhome.js'
import xwbuyingcenter from './views/xwbuyingcenter/xwbuyingcenter.js'
import xwProductintroduct from './views/xwProductintroduct/xwProductintroduct.js'
import xwwelfarecenter from './views/xwwelfarecenter/xwwelfarecenter.js'
import xwbaodanPDF from './views/xwbaodanPDF/xwbaodanPDF.js'


class App extends React.Component {
  render () {
    return (
      <div className="App" >
        {/* <BindProduction openid={this.state.openid}></BindProduction> */}
        <Router>
          <Route path="/weixin/BindProduction" component={BindProduction} ></Route>
          <Route path="/weixin/MyProduction" component={MyProduction} ></Route>
          <Route path="/weixin/AfterSales" component={AfterSales} ></Route>
          <Route path="/weixin/Privacy" component={Privacy} ></Route>
          <Route path="/weixin/UserRules" component={UserRules} ></Route>
          <Route path="/weixin/Insurancepolicy" component={Insurancepolicy} ></Route>
          <Route path="/weixin/guardianproject" component={guardianproject} ></Route>
          <Route path="/weixin/guardianhome" component={guardianhome} ></Route>
          <Route path="/weixin/xwbuyingcenter" component={xwbuyingcenter} ></Route>
          <Route path="/weixin/xwProductintroduct" component={xwProductintroduct} ></Route>
          <Route path="/weixin/xwwelfarecenter" component={xwwelfarecenter} ></Route>
          <Route path="/weixin/xwbaodanPDF" component={xwbaodanPDF} ></Route>
        </Router>
      </div>)
  };
}

export default App;
