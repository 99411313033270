import React from 'react';
import '../Common/common.scss';
// import Javapdf from './Java.pdf'
import '../xwcss/bxbase.scss';
import './xwbaodanPDF.scss';
// import { Icon, Toast } from 'antd-mobile';
// import { Toast } from 'antd-mobile';
import fill from 'lodash/fill';
// import { pdfPathss } from '../../api/api.js';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
// import { Pagination } from 'antd'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



class xwbaodanPDF extends React.Component {

    state = {
        // pdfurl: 'https://guli-file-by2020.oss-cn-shenzhen.aliyuncs.com/Java%E5%BC%80%E5%8F%91%E6%89%8B%E5%86%8C.pdf?officePreviewType=pdf',
        pdfpath: null,
        PDFBlob: null,
        numPages: null,
        setNumPages: null,
        pageNumber: 1,
        setPageNumber: 1,
        currentPage: 1,
        pageclass1: 'pageclass1'

    }

    // // render挂载后 UI渲染完成后调用，且只执行一
    componentDidMount = () => {
        const arr = this.props.location.search.split('&') // ['?s=', 'f=7']
        const numPages1 = arr[2].substr(9) // STY20171011124209535
        // console.log(this.props.location.search.split('&')[2].substr(9))
        this.setState({
            numPages: numPages1
        })

        console.log(localStorage.getItem('numPages2'))

        console.log(localStorage.getItem('PDFBlob1'))

        var objMeta = document.getElementsByTagName("meta");
        objMeta[1].content = "width=device-width, initial-scale=1.0";

    }
    onDocumentLoadSuccess = ({ page }) => {
        // this.setState({ numPages: page });
        // console.log(6787668687)
        // console.log(page)
    }


    render() {
        const zongshunum = parseInt(localStorage.getItem('numPages2')) 
        const pingmuwidth = document.body.clientWidth
        // console.log(pingmuwidth)
        return (
            <div className="baodanpdf" id="example1">
                <Document
                    // title='12542523'
                    file={localStorage.getItem('PDFBlob1')}	//文件路径或者base64
                    // file={'https://xingyun2020.oss-cn-shenzhen.aliyuncs.com/20201123/1d0c55fa5f7a45c8bfa819b232d2a487.pdf'}	//文件路径或者base64
                    onLoadSuccess={this.onDocumentLoadSuccess} //成功加载文档后调用
                    onLoadError={console.error} //加载失败时调用
                    renderMode="canvas"	 //定义文档呈现的形式
                    loading="正在努力加载中" //加载时提示语句
                    // width={414}
                    externalLinkTarget="_blank"
                >

                    {fill(new Array(zongshunum), '').map((item, index) => {
                        return (
                            <Page
                                // loading="分页加载中..."
                                noData=""
                                renderMode="canvas"
                                scale={1}
                                // className={this.state.pageclass1}
                                width={pingmuwidth}
                                key={index}
                                pageNumber={index + 1}
                                renderTextLayer={true}
                                renderInteractiveForms={true}
                            />

                        );
                    })}
                </Document>

                {/* <p className="yema"> {this.state.pageNumber}/{this.state.numPages}</p> */}
                {/* </div> */}
            </div >
        )
    }
}
export default xwbaodanPDF;