import React from 'react';
import '../Common/common.scss';
import logo from '../../logo.png'
import '../xwcss/bxbase.scss';
import './Insurancepolicy.scss';
import { InputItem, WhiteSpace, Accordion, List, Picker, DatePicker, Button, Icon, Toast } from 'antd-mobile';
import { getUserById, wodebaodanxinxi, baodanPDF, pdfPathss } from '../../api/api.js';
import { getWeixinOpenid, } from '../../api/weixin.js';
// import jsonProvice from '../../assets/city_code.json';
import store from '../../store/store.js';


const seasons = [
    [
        {
            label: '1年',
            value: '1年',
        },
        {
            label: '2年',
            value: '2年',
        },
        {
            label: '3年',
            value: '3年',
        },
    ]
];
const shouhuxiangmu = [
    [
        {
            label: '人身意外险',
            value: '人身意外险',
        },
        {
            label: '人身意外险2',
            value: '人身意外险2',
        },
        {
            label: '人身意外险3',
            value: '人身意外险3',
        },
    ]
];
const toubaoguanxi = [
    [
        {
            label: '本人',
            value: '本人',
        },
        {
            label: '亲属',
            value: '亲属',
        },
        {
            label: '朋友',
            value: '朋友',
        }
    ]
];
const zhengjianleixing = [
    [
        {
            label: '身份证',
            value: '1',
        },
        {
            label: '居住证',
            value: '2',
        },
        {
            label: '外国证',
            value: '3',
        },
    ]
];
const bxxingbie = [
    [
        {
            label: '男',
            value: '1',
        },
        {
            label: '女',
            value: '2',
        }
    ]
];
const bxnianling = [
    [
        {
            label: '18岁',
            value: '18岁',
        },
        {
            label: '19岁',
            value: '19岁',
        }
    ]
];
const bxzhiyedalei = [
    [
        {
            label: '一般职业',
            value: '一般职业',
        },
        {
            label: '一般职业1',
            value: '一般职业1',
        }
    ]
];

const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);
class Insurancepolicy extends React.Component {
    state = {

        time: now,
        // utcDate: utcNow,
        dpValue: null,
        customChildValue: null,
        visible: false,
        date1: now,
        date2: now,
        date3: now,
        sValue: ['1年'],
        value: ['1年'],
        sValue1: ['人身意外险'],
        value1: ['人身意外险'],
        sValue3: [],
        value3: [],
        sValue4: [],
        value4: [],
        sValue5: [],
        value5: [],
        sValue6: [],
        value6: [],
        sValue7: [],
        value7: [],
        classcolor1: '',
        classcolor2: '',
        baozhangedu: '10万',
        beibaorenname: '',
        loading: false,
        buttonLoading: false,
        // getopenid: false,
        userNotExist: false,//用户不存在要提示先去绑定
        baodanhtml: false,
        baodanhtmlbtn: false,
        touId: '', //拿保单pdf,
        href: ''
    }
    // render挂载后 UI渲染完成后调用，且只执行一次
    componentDidMount = () => {
        var objMeta = document.getElementsByTagName("meta");
        objMeta[1].content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
        // console.log(store.getState(this.aaa))
        document.body.scrollIntoView();
        getWeixinOpenid(this._getUserById)
    }
    _getUserById = (openid) => {
        this.setState({
            buttonLoading: true,
            loading: true
        });
        getUserById(openid).then(res => {
        
            const tempdata = res.data.data
            // console.log(tempdata);
            if (!tempdata) {
                //用户不存在
                this.setState({
                    buttonLoading: false,
                    loading: false,
                    userNotExist: true,
                })
                // Toast.success('用户不存在', 1);

                // this.props.history.push('/weixin/MyProduction?code=789&state=')

            } else {
                // 拿保险信息
                wodebaodanxinxi(store.getState()).then(res => {  //提交
                    console.log(res);
                    this.setState({
                        buttonLoading: false,
                        loading: false
                    })
                    if (res.data.success) {
                        // alert(76867876)
                        const tempdata = res.data.data
                        if (tempdata === null) {
                            this.setState({
                                baodanhtmlbtn: true,
                                baodanhtml: false,
                            })
                            return false
                        }
                        this.setState({
                            baodanhtmlbtn: false,
                            baodanhtml: true,
                        })
                        // Toast.success(res.data.message, 1);
                        // console.log(new Date(tempdata.createTime))
                        // console.log(tempdata.cardType)
                        toubaoguanxi[0].push({ label: tempdata.relationShip, value: tempdata.relationShip })
                        bxnianling[0].push({ label: tempdata.age, value: tempdata.age })
                        bxzhiyedalei[0].push({ label: tempdata.occupation, value: tempdata.occupation })
                        this.setState({
                            classcolor1: tempdata.state === 1 ? 'bx-color1' : '',
                            classcolor2: tempdata.state === 2 ? 'bx-color1' : '',
                            date1: new Date(tempdata.createTime),
                            date2: new Date(tempdata.deleteTime),
                            sValue3: [tempdata.relationShip],
                            beibaorenname: tempdata.name,
                            sValue4: [String(tempdata.cardType)],
                            sValue5: [String(tempdata.gender)],
                            data3: new Date(tempdata.birthday).toLocaleDateString(),
                            sValue6: [tempdata.age],
                            sValue7: [tempdata.occupation],
                            touId: tempdata.userId
                        })
                    }
                    // else {
                    //     this.props.history.push('/weixin/guardianhome?code=789&state=')
                    // }
                }).catch(err => {
                    // Toast.success(err, 1);
                    this.setState({
                        buttonLoading: false,
                        loading: false,
                        userNotExist: false,
                        baodanhtmlbtn: false,
                        baodanhtml: false,
                    })
                })
            }
        }).catch(err => {
            this.setState({
                loading: false,
                userNotExist: false,
            })
        })
    }
    onbaodan = () => {
        // //保险PDF
        this.setState({
            loading: true
        });
        baodanPDF(this.state.touId).then(res => {  //提交
            console.log(res)

            if (res.data.success) {
                const tempdata = res.data.data
                if (tempdata) {
                    if (tempdata.pdfPath === '') {
                        this.setState({
                            loading: false,
                        })
                        Toast.success(res.data.message, 2); return false
                    }
                    console.log(tempdata.pdfPath)
                    // window.location.href = 'https://guli-file-by2020.oss-cn-shenzhen.aliyuncs.com/Java%E5%BC%80%E5%8F%91%E6%89%8B%E5%86%8C.pdf';
                    // let url = window.URL.createObjectURL(new Blob(['https://guli-file-by2020.oss-cn-shenzhen.aliyuncs.com/Java%E5%BC%80%E5%8F%91%E6%89%8B%E5%86%8C.pdf']))
                    // let url = window.URL.createObjectURL(new Blob([tempdata]))
                    // let link = document.createElement('a')
                    // link.style.display = 'none'
                    // link.href = url
                    // link.download = '我的保单.pdf'; //下载后文件名
                    // link.setAttribute('download', '我的保单.pdf')
                    // document.body.appendChild(link)
                    // link.click()

                    // //拿保险PDF
                    pdfPathss(tempdata.pdfPath).then(res => {  //提交
                        this.setState({
                            loading: false,
                        })
                        // console.log(res)
                        //插件
                        if (res.data.type === 'application/json') {
                            Toast.success(res.data.message, 2); return false
                            // Msg.alert("PDF文件获取失败!")
                        } else {
                            let blob = res.data;
                            //   this.setState({blob:this.response})
                            let reader = new FileReader();
                            reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
                            reader.addEventListener("load", () => {
                                // console.log(reader, 'reader.result')
                                let base64 = reader.result
                                // console.log(base64)
                                // return
                                localStorage.setItem('PDFBlob1', base64)
                                localStorage.setItem('numPages2', tempdata.pageSize)
                                //   this.setState({
                                //     PDFBlob: base64, 
                                //   })
                                this.props.history.push('/weixin/xwbaodanPDF?code=789&state=&pageSize='+tempdata.pageSize)

                            });
                        }

                    }).catch(err => {
                        this.setState({})
                    })
                }else{
                    Toast.success(res.data.message, 2);
                    this.setState({
                        loading: false,
                    })
                }

            } else {
                Toast.success(res.data.message, 2);
                return false
            }
        }).catch(err => {
            this.setState({
                loading: false,
            })
        })
    }
    render() {
        return (
            <div className="wodebaodan">
                {!this.state.userNotExist && <div>
                    <div className="baoxians">
                        <div className="baoxian">
                            <div className="headContent">
                                <img className='logo' src={logo} alt="" />
                                <span>我的保单</span>
                            </div>
                            {this.state.loading &&
                                <div className='flexcenter'>
                                    <Icon type="loading" size='lg' />
                                </div>}
                            {this.state.baodanhtml && <div>
                                <div className="baoxiantitle">星云健康守护者计划————人身意外险</div>
                                <div className="bx-jihuozhuantai">
                                    <i>项目状态</i>
                                    <span className={this.state.classcolor1}>已激活</span>
                                    <em>/</em>
                                    <span className={this.state.classcolor2}>待激活</span>
                                </div>
                                <Accordion defaultActiveKey="0" className="my-accordion my-accordion1">
                                    <Accordion.Panel header="项目信息" className="bx-xiangmuxinxi">
                                        <List className="my-list">
                                            <DatePicker
                                                mode="date"
                                                title="Select Date"
                                                value={this.state.date1}
                                                minDate={new Date(1900, 1, 1, 0, 0, 0)}

                                                maxDate={new Date(2030, 12, 31, 23, 59, 59)}
                                                disabled='false'
                                                onChange={date1 => this.setState({ date1 })}
                                            >
                                                <List.Item arrow="horizontal">生效日：</List.Item>
                                            </DatePicker>


                                            <DatePicker
                                                mode="date"
                                                title="Select Date1"
                                                value={this.state.date2}
                                                disabled='false'
                                                minDate={new Date(1900, 1, 1, 0, 0, 0)}

                                                maxDate={new Date(2030, 12, 31, 23, 59, 59)}
                                                onChange={date2 => this.setState({ date2 })}
                                            >
                                                <List.Item arrow="horizontal">届满日：</List.Item>
                                            </DatePicker>

                                            <Picker
                                                data={seasons}
                                                title="选择期限"
                                                cascade={false}
                                                extra="选择期限"
                                                disabled='false'
                                                value={this.state.sValue}
                                                onChange={v => this.setState({ sValue: v })}
                                            >
                                                <List.Item arrow="horizontal">有效期限：</List.Item>
                                            </Picker>

                                            <Picker
                                                data={shouhuxiangmu}
                                                title="守护项目"
                                                cascade={false}
                                                extra="请选择"
                                                disabled='false'
                                                value={this.state.sValue1}
                                                onChange={v => this.setState({ sValue1: v })}
                                                onOk={v => this.setState({ sValue1: v })}
                                            >
                                                <List.Item arrow="horizontal">守护项目：</List.Item>
                                            </Picker>
                                            <InputItem
                                                className="bx-xingming"
                                                value={this.state.baozhangedu}
                                                editable={false}
                                            >保障额度：</InputItem>

                                        </List>
                                    </Accordion.Panel>
                                </Accordion>
                                <Accordion defaultActiveKey="0" className="my-accordion my-accordion2 ">
                                    <Accordion.Panel header="被保人信息">

                                        <List className="my-list">
                                            <Picker
                                                data={toubaoguanxi}
                                                title="关系"
                                                cascade={false}
                                                extra="请选择"
                                                disabled='false'
                                                value={this.state.sValue3}
                                                onChange={v => this.setState({ sValue3: v })}
                                                onOk={v => this.setState({ sValue3: v })}
                                            >
                                                <List.Item arrow="horizontal">与投保人关系：</List.Item>
                                            </Picker>
                                            <InputItem
                                                className="bx-xingming"
                                                value={this.state.beibaorenname}
                                                editable={false}
                                            >姓名：</InputItem>

                                            <Picker
                                                data={zhengjianleixing}
                                                title="请选择证件类型"
                                                cascade={false}
                                                extra="请选择"
                                                disabled='false'
                                                value={this.state.sValue4}
                                                onChange={v => this.setState({ sValue4: v })}
                                                onOk={v => this.setState({ sValue4: v })}
                                            >
                                                <List.Item arrow="horizontal">证件类型：</List.Item>
                                            </Picker>
                                            <Picker
                                                data={bxxingbie}
                                                title="请选择上性别"
                                                cascade={false}
                                                extra="请选择"
                                                disabled='false'
                                                value={this.state.sValue5}
                                                onChange={v => this.setState({ sValue5: v })}
                                                onOk={v => this.setState({ sValue5: v })}
                                            >
                                                <List.Item arrow="horizontal">性别：</List.Item>
                                            </Picker>

                                            <DatePicker
                                                mode="date"
                                                title="Select Date"
                                                value={this.state.date3}
                                                minDate={new Date(1900, 1, 1, 0, 0, 0)}
                                                maxDate={new Date(2030, 12, 31, 23, 59, 59)}
                                                disabled='false'
                                                onChange={date3 => this.setState({ date3 })}
                                            >
                                                <List.Item arrow="horizontal">出生日期：</List.Item>
                                            </DatePicker>

                                            <Picker
                                                data={bxnianling}
                                                title="请选择年龄"
                                                cascade={false}
                                                extra="请选择"
                                                disabled='false'
                                                value={this.state.sValue6}
                                                onChange={v => this.setState({ sValue6: v })}
                                                onOk={v => this.setState({ sValue6: v })}
                                            >
                                                <List.Item arrow="horizontal">年龄：</List.Item>
                                            </Picker>
                                            <Picker
                                                data={bxzhiyedalei}
                                                title="请选择职业"
                                                cascade={false}
                                                extra="请选择"
                                                disabled='false'
                                                value={this.state.sValue7}
                                                onChange={v => this.setState({ sValue7: v })}
                                                onOk={v => this.setState({ sValue7: v })}
                                            >
                                                <List.Item arrow="horizontal">职业大类：</List.Item>
                                            </Picker>

                                        </List>

                                    </Accordion.Panel>
                                </Accordion>
                                <div className="cbao"> 本产品由平安保险股份有限公司承保 </div>
                                <Button className="bx-btn" onClick={this.onbaodan}>获取保单</Button>

                            </div>}
                            {this.state.baodanhtmlbtn && <div>
                                <WhiteSpace size="xl" />
                                <span>您未参与过守护者计划</span>
                                <WhiteSpace size="md" />
                                <Button onClick={() => { this.props.history.push('/weixin/guardianhome?code=789&state=') }} className='bindButton'>前往参与 </Button>
                            </div>}
                        </div>
                    </div>
                </div>
                }
                {/* // 没有用户弹框跳转  */}
                {this.state.userNotExist && <div>
                    <div className="headContent">
                        <img className='logo' src={logo} alt="" />
                        <span>守护者计划</span>
                    </div>
                    <WhiteSpace size="xl" />
                    <span>用户不存在 请先绑定设备</span>
                    <WhiteSpace size="md" />
                    <Button onClick={() => { this.props.history.push('/weixin/BindProduction?code=789&state=') }} className='bindButton'>
                        前往绑定
                        </Button>
                </div>}
            </div>
        )
    }
}
export default Insurancepolicy;