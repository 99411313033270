import React from 'react';
import '../Common/common.scss'
import userrule1 from '../../assets/userrule1.png'
import userrule2 from '../../assets/userrule2.png'
import userrule3 from '../../assets/userrule3.png'

class UserRules extends React.Component {
    render () {
        return (
            <div>
                <img className='statuteIMG' src={userrule1} alt="" />
                <img className='statuteIMG' src={userrule2} alt="" />
                <img className='statuteIMG' src={userrule3} alt="" />
            </div>
        )
    }
}
export default UserRules;