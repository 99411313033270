
import axios from "axios";
import { Toast } from 'antd-mobile';
import config from '../config'

// 添加响应拦截器
axios.interceptors.response.use(
    function (response) {
        if (response.status !== 200 || response.data.success === false) {
            Toast.fail(response.data.message, 2);
        }
        return response;
    },
    function (error) {
        console.log(error)
        Toast.fail(
            `发生了一个错误:${error.response.status}${error.response.statusText}`, 1
        );
        return Promise.reject(error);
    }
);

// 通过微信code获取用户openid
export function snsOpenID(code) {
    return axios({
        // method: "post",
        method: 'get',
        url: `/sns/oauth2/access_token?appid=${config.appid}&secret=${config.secret}&code=${code}&grant_type=authorization_code`
    });
}

// 获取js sdk签名
export function getJsDK(url = 'abc', accese_token) {
    return axios({
        method: "post",
        url: "/api/wx/getJsDK",
        data: {
            url: url,
            accessToken: accese_token
        },
    });
}

// openid获取当前用户信息
export function getUserById(openid = 'abc') {
    return axios({
        method: "get",
        url: "/api/user/getUserById",
        params: {
            openid: openid,
        },
    });
}

// 获取验证码
export function sendDuanxin(phone = 'abc') {
    return axios({
        method: "get",
        url: "/api/user/sendDuanxin",
        params: {
            phone: phone,
        },
    });
}

// 绑定设备
export function bindEquipment(openid, equipmentCode, userdate) {
    return axios({
        method: "post",
        url: "/api/user/bindEquipment",
        params: {
            openid: openid,
            equipmentCode: equipmentCode
        },
        data: userdate
    });
}

// openid获取设备类型
export function selectListEqBind(openid = 'abc') {
    return axios({
        method: "get",
        url: "/api/user/selectListEqBind",
        params: {
            openid: openid,
        },
    });
}

// openid获取设备类型
export function getBindEqType(openid = 'abc') {
    return axios({
        method: "get",
        url: "/api/user/getBindEqType",
        params: {
            openid: openid,
        },
    });
}

// openid获取设备类型
export function getBindEqTypeName(openid = 'abc', type = 'abc') {
    return axios({
        method: "get",
        url: "/api/user/getBindEqTypeName",
        params: {
            openid: openid,
            type: type
        },
    });
}

// 提交售后申请
export function addLeaveMessage(openid = 'abc', userdate) {
    return axios({
        method: "post",
        url: "/api/user/addLeaveMessage",
        params: {
            openid: openid
        },
        data: userdate
    });
}
// 修改信息
export function updateByPrimaryKey(openid = 'abc', userdate) {
    return axios({
        method: "post",
        url: "/api/user/updateByPrimaryKey",
        params: {
            openid: openid
        },
        data: userdate
    });
}

//删除设备
export function deleteByPrimaryKey(deviceid) {
    return axios({
        method: "post",
        url: "/api/user/deleteByPrimaryKey",
        params: {
            id: deviceid
        }
    });
}

// openid获取设备类型
export function getKeyQr(url) {
    return axios({
        method: "get",
        url: "/api/wx/getKeyQr",
        params: {
            url: url
        },
    });
}

//保险

//1/加入守护者计划  微信用户得id提交
export function shouhezhejihua(openid = 'abc', userdate) {
    return axios({
        method: "post",
        url: "/api/protected/addBaoXian",
        // url: "http://aweirok.viphk.ngrok.org/protected/addProtected",
        params: {
            openid: openid
        },
        data: userdate
    });
}
//查看我的保险信息
export function wodebaodanxinxi(openid = 'abc') {
    return axios({
        method: "get",
        // url: "/api/protected/getMyProtected",
        url: "/api/protected/getBaoXianById",
        params: {
            openid: openid
        },
    });
}
//保单pdf
export function baodanPDF(userId='abc') {
    return axios({
        method: "get",
        // setRequestHeader:'Content-Type, application/x-www-form-urlencoded',
        // responseType:'blob', 
        url: "/api/protected/getPdfByProtectedId",
        // url: "/api/protected/downPDF",
        contentType:'application/pdf', 
        // responseType: 'blob'
        params: {
            userId: userId
        },
    });
}
//保单pdf
export function pdfPathss(pdfPath='abc') {
    return axios({
        method: "get",
        url: "/api/protected/getPDF",
        // url: "/api/protected/downPDF",
        contentType:'application/pdf', 
        responseType: 'blob',
        params: {
            pdfPath: pdfPath
        },
    });
}


//保单pdf
export function downloadPDF(url) {
    return axios({
        method: "get",
        // setRequestHeader:'Content-Type, application/x-www-form-urlencoded',
        // responseType:'blob', 
        url: url,

    });
}
//用户留言信息
export function LeaveMessageinfo(openid = 'abc') {
    return axios({
        method: "get",
        url: "/api/user/getUserLeaveMessageById",
        params: {
            openid: openid
        },
    });
}