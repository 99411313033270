
import config from '../config'
import { snsOpenID, getJsDK, getKeyQr } from './api.js'

import wx from 'weixin-jsapi'

import store from '../store/store.js'
import { changeOpenid } from '../store/action.js'

export function getWeixinOpenid (callback, param) {
    const name = window.location.search.substr(1).split('&')[0].split('=')[0]
    let code = ''
    if (name === 'code') {
        code = window.location.search.substr(1).split('&')[0].split('=')[1]
    }
    else {
        code = window.location.search.substr(1).split('&')[1].split('=')[1]
    }

    if (!store.getState() || store.getState() === 'undefined') {
        snsOpenID(code).then(res => {
            if (!res.data.openid) {
                //如果没获取到openid就从session中取
                const seesion_openid = sessionStorage.getItem("myopenid");
                const seesion_access_token = sessionStorage.getItem("myaccess_token");
                store.dispatch(changeOpenid(seesion_openid))
                callback(seesion_openid)
                weixinJSSDK(seesion_access_token)
            }
            else {
                sessionStorage.setItem("myopenid", res.data.openid);
                sessionStorage.setItem("myaccess_token", res.data.access_token);
                store.dispatch(changeOpenid(res.data.openid))
                callback(res.data.openid)
                weixinJSSDK(res.data.access_token)
            }
        })
    }
    else {
        const seesion_access_token = sessionStorage.getItem("myaccess_token");
        callback(store.getState())
        weixinJSSDK(seesion_access_token)
    }
}

export function weixinJSSDK (access_token) {
    const url = window.location.href;

    getJsDK(url, access_token).then(res => {
        const data = res.data.data
        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: config.appid, // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.noncestr, // 必填，生成签名的随机串
            signature: data.signature,// 必填，签名
            jsApiList: ['scanQRCode', 'chooseImage'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function (res) {
            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        });
        wx.error(function (error) {
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
    }).catch(err => { })

}

// export function chooseImage (callback) {
//     wx.scanQRCode({
//         needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
//         scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
//         success: (res) => {
//             let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
//             getKeyQr(result).then(res_1 => {
//                 setTimeout(() => {
//                     callback(res_1.data.data);
//                 }, 1000);
//             }).catch(err => {
//                 callback(null);
//             })
//         }
//     });
// } 

export function chooseImage (callback) {
  wx.scanQRCode({
    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
    scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
    success: (res) => {
      console.log(res)
      let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
        getKeyQr(result).then(res_1 => {
        setTimeout(() => {
          callback(res_1.data.data);
        }, 1000);
      }).catch(err => {
        console.log(err)
        callback(null);
      })
    },
    fail: () => {
      console.log('失败')
      callback(null);
    }
  });
} 

//拍照或从手机相册中选图接口
// export function aa(){
//   wx.chooseImage({
//     count: 1, // 默认9
//     sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
//     sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
//     success: function (res) {
//       // var localIds = res.localIds; // 返回选定照片的本地 ID 列表，localId可以作为 img 标签的 src 属性显示图片
//       console.log(res)
//     }
//   });
// }
    