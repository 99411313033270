import React from 'react';
import './MyProduction.scss';
import { List, InputItem, WhiteSpace, Flex, TextareaItem, Button, Icon, DatePicker, Picker, Popover, Modal, Toast } from 'antd-mobile';
import logo from '../../logo.png'
import jsonProvice from '../../assets/city_code.json'

import store from '../../store/store.js'

import { selectListEqBind, sendDuanxin, updateByPrimaryKey, deleteByPrimaryKey } from '../../api/api.js'
import { getWeixinOpenid } from '../../api/weixin.js'

const alertModel = Modal.alert;
const Item = Popover.Item;
const genderList = [
    {
        value: 1,
        label: '男'
    },
    {
        value: 2,
        label: '女'
    }]

const regRule = new RegExp(
    /^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9])[0-9]{8}$/
)

let timeIntevalOBJ = null
let productList = []
let timeOut = 60
class MyProduction extends React.Component {
    state = {
        name: '',
        phone: '',
        gender: '',
        birth: '',
        address: '',
        userNotExist: false,//用户不存在要提示先去绑定
        loading: true,
        needChangeUserInfo: false,
        hasnameError: false,
        hasphoneError: false,
        hasCodeError: false,
        changename: '',
        changephone: '',
        changeverifyCode: '',
        changegender: '',
        changebirth: '',
        changeaddress: '',
        changedetailaddress: '',
        disableGetText: false,
        visible: false
    }

    componentDidMount = () => {
        getWeixinOpenid(this._selectListEqBind)
    }

    _selectListEqBind = (openid) => {
        this.setState({ loading: true })
        selectListEqBind(openid).then(res => {
            // console.log(res)
            const tempdata = res.data.data
            if (!tempdata || tempdata.totalItems === 0) {
                //没有这个用户
                this.setState({
                    name: '',
                    phone: '',
                    gender: '',
                    birth: '',
                    address: '',
                    userNotExist: true,
                    loading: false
                })
            } else {
                productList = tempdata.list
                this.setState({
                    name: tempdata.list[0].name,
                    phone: tempdata.list[0].phone,
                    gender: tempdata.list[0].gender === 1 ? '男' : '女',
                    birth: !tempdata.list[0].birthDay ? '' : new Date(tempdata.list[0].birthDay).toLocaleDateString(),
                    address: `${tempdata.list[0].province} ${tempdata.list[0].city} ${tempdata.list[0].area} ${tempdata.list[0].address}`,
                    userNotExist: false,
                    loading: false
                })
            }
        }).catch(err => {
            this.setState({
                name: '',
                phone: '',
                gender: '',
                birth: '',
                address: '',
                userNotExist: true,
                loading: false
            })
        })
    }

    ongetVerifyCode = () => {
        const test = regRule.test(this.state.phone.replace(/\s/g, ''))
        if (!test) {
            this.setState({ hasphoneError: true })
        } else {
            sendDuanxin(this.state.phone.replace(/\s/g, ""));
            this.setState({ disableGetText: true, hasphoneError: false })
            timeIntevalOBJ = setInterval(() => {
                if (timeOut === 0) {
                    clearInterval(timeIntevalOBJ)
                    this.setState({ disableGetText: false })
                    timeOut = 60
                }
                // this.setState({ timeOut: this.state.timeOut - 1 })
               return timeOut--
            }, 1000)
        }
    }

    onChangeUserInfoClick = () => {
        this.setState({
            needChangeUserInfo: true,
            changename: this.state.name,
            changephone: "",
            changeverifyCode: '',
            changegender: [this.state.gender === '男' ? 1 : 2],
            changebirth: !this.state.birth ? "" : new Date(this.state.birth),
            changeaddress: '',
            changedetailaddress: '',
        })
    }

    onConfirmChangeUserInfo = () => {

        const test = regRule.test(this.state.changephone.replace(/\s/g, ''))
        if (!test || !this.state.changename || !this.state.changeverifyCode) {
            this.setState({
                hasphoneError: !test,
                hasnameError: !this.state.changename,
                hasCodeError: !this.state.changeverifyCode,
            });
        } else {
            this.setState({
                hasphoneError: false,
                hasnameError: false,
                hasCodeError: false
            });
            const userdata = {
                name: this.state.changename,
                phone: this.state.changephone.replace(/\s/g, ""),
                ipCode: this.state.changeverifyCode,
                gender: this.state.changegender === '男' ? 1 : 2,
                birthDay: new Date(this.state.changebirth).getTime(),
                province: !this.state.changeaddress[0] ? '' : this.state.changeaddress[0],
                city: !this.state.changeaddress[1] ? '' : this.state.changeaddress[1],
                area: !this.state.changeaddress[2] ? '' : this.state.changeaddress[2],
                address: !this.state.changedetailaddress ? '' : this.state.changedetailaddress,
            }
            this.setState({ buttonLoading: true })
            updateByPrimaryKey(store.getState(), userdata).then(res => {
                this.setState({
                    buttonLoading: false
                })
                if (res.data.success) {
                    this.setState({
                        needChangeUserInfo: false
                    })
                    this._selectListEqBind(store.getState())
                }
            }).catch(err => {
                this.setState({ buttonLoading: false })
            })
        }

    }

    onconfirmDelete = (id) => {
        this.setState({ loading: true })
        deleteByPrimaryKey(id).then(res => {
            this.setState({ loading: false })
            if (res.data.success) {
                Toast.success(res.data.message, 1);
                this._selectListEqBind(store.getState())
            }
        }).catch(err => {
            this.setState({ loading: false })
        })
    }

    render () {
        return (
            <div className="MyProduction">
                <div className="headContent">
                    <img className='logo' src={logo} alt="" />
                    <span>我的产品</span>
                </div>
                {this.state.loading &&
                    <div className='flexcenter'>
                        <Icon type="loading" size='lg' />
                    </div>}
                {!this.state.userNotExist && !this.state.loading && !this.state.needChangeUserInfo &&
                    <List>
                        <WhiteSpace size="md" />
                        <InputItem
                            value={this.state.name}
                            editable={false}
                        >姓名</InputItem>

                        <WhiteSpace size="md" />
                        <InputItem
                            value={this.state.phone}
                            editable={false}
                        >手机号</InputItem>

                        <WhiteSpace size="md" />
                        <InputItem
                            value={this.state.gender}
                            editable={false}
                        >性别</InputItem>

                        <WhiteSpace size="md" />
                        <InputItem
                            value={this.state.birth}
                            editable={false}
                        >生日</InputItem>

                        <WhiteSpace size="md" />
                        <TextareaItem
                            title="详细地址"
                            rows={2}
                            value={this.state.address}
                            editable={false}
                        ></TextareaItem>

                        {productList.map((res, index) => {
                            // console.log(res)
                            return <List renderHeader={() => `产品${index + 1}`} key={index} className="productlist">
                                <Flex className='greyFlex'>
                                    <Flex.Item>
                                        <InputItem
                                            value={res.equipmentName}
                                            editable={false}
                                        >产品名称</InputItem>
                                    </Flex.Item>
                                    <Flex.Item >
                                        <Popover mask
                                            overlayClassName="fortest"
                                            overlayStyle={{ color: 'currentColor' }}
                                            visible={this.state.visible}
                                            onSelect={() =>
                                                alertModel('删除设备', '确定?', [
                                                    { text: '取消', onPress: () => console.log('cancel') },
                                                    { text: '确定', onPress: () => this.onconfirmDelete(res.id) },
                                                ])
                                            }
                                            overlay={[
                                                (<Item key="1" value="scan" >删除设备</Item>),

                                            ]}
                                            align={{
                                                overflow: { adjustY: 0, adjustX: 0 },
                                                offset: [-10, 0],
                                            }}
                                        ><div style={{
                                            height: '100%',
                                            padding: '0 15px',
                                            marginRight: '-15px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        >
                                                <Icon type="ellipsis" />
                                            </div>
                                        </Popover>
                                    </Flex.Item>
                                </Flex>

                                <InputItem
                                    value={res.typeName}
                                    editable={false}
                                >产品型号</InputItem>
                                <InputItem
                                    value={res.equipmentCode}
                                    editable={false}
                                >产品编码</InputItem>


                                <InputItem
                                    value={new Date(res.createTime).toLocaleDateString()}
                                    editable={false}
                                >激活时间</InputItem>

                                <InputItem
                                    value={new Date(res.deleteTime).toLocaleDateString()}
                                    editable={false}
                                >在保时间</InputItem>


                            </List>
                        })}

                        <WhiteSpace size="md" />
                        <Button className='bindButton' onClick={this.onChangeUserInfoClick}>
                            修改我的信息
                        </Button>
                        <Button className='bindButton' onClick={() => { this.props.history.push('/weixin/guardianhome/guardianhome?code=789&state=')}}>
                            加入守护者计划
                        </Button>
                    </List>
                }
                {
                    this.state.userNotExist &&
                    <div>
                        <WhiteSpace size="xl" />
                        <span>用户不存在或未绑定设备</span>
                        <WhiteSpace size="md" />
                        <Button onClick={() => { this.props.history.push('/weixin/BindProduction?code=789&state=') }} className='bindButton'>
                            前往绑定
                        </Button>
                    </div>
                }
                {
                    this.state.needChangeUserInfo &&
                    <List>
                        <InputItem
                            placeholder="输入姓名"
                            value={this.state.changename}
                            error={this.state.hasnameError}
                            onChange={changename => this.setState({ changename })}
                        >姓名</InputItem>

                        <WhiteSpace size="md" />
                        <InputItem
                            type="phone"
                            placeholder="输入手机号"
                            error={this.state.hasphoneError}
                            value={this.state.changephone}
                            onChange={changephone => this.setState({ changephone })}
                        >手机号码</InputItem>

                        <WhiteSpace size="md" />
                        <Flex>
                            <Flex.Item>
                                <InputItem
                                    type="number"
                                    error={this.state.hasCodeError}
                                    placeholder="输入验证码"
                                    value={this.state.changeverifyCode}
                                    onChange={changeverifyCode => this.setState({ changeverifyCode })}
                                >验证码</InputItem>
                            </Flex.Item>
                            <Flex.Item>
                                {/* <Button size='small' disabled={this.state.disableGetText} onClick={this.ongetVerifyCode}>{!this.state.disableGetText ? '获取验证码' : timeOut + 's'}</Button> */}
                                <Button size='small' disabled={this.state.disableGetText} onClick={this.ongetVerifyCode}>获取验证码</Button>
                            </Flex.Item>
                        </Flex>

                        <WhiteSpace size="md" />
                        <Picker cols={1}
                            data={genderList}
                            value={this.state.changegender}
                            onOk={changegender => this.setState({ changegender })} >
                            <List.Item arrow="horizontal">性别</List.Item>
                        </Picker>

                        <WhiteSpace size="md" />
                        <DatePicker
                            mode="date"
                            title="选择"
                            minDate={new Date(1900, 1, 1, 0, 0, 0)}
                            maxDate={new Date(2030, 12, 31, 23, 59, 59)}
                            value={new Date(this.state.changebirth)}
                            format='YYYY-MM-DD'
                            onOk={changebirth => this.setState({ changebirth })}
                        >
                            <List.Item arrow="horizontal">生日</List.Item>
                        </DatePicker>

                        <WhiteSpace size="md" />
                        <Picker
                            data={jsonProvice}
                            value={this.state.changeaddress}
                            onOk={(changeaddress) => this.setState({ changeaddress })}
                        >
                            <List.Item arrow="horizontal">地区</List.Item>
                        </Picker>
                        <InputItem
                            placeholder="输入详细地址"
                            value={this.state.changedetailaddress}
                            onChange={changedetailaddress => this.setState({ changedetailaddress })}
                        >详细地址</InputItem>
                        <Button className='bindButton' loading={this.state.buttonLoading} onClick={this.onConfirmChangeUserInfo}>
                            确认修改
                        </Button>
                    </List>
                }
            </div >
        );
    }
}


export default MyProduction;
