import React from 'react';
import '../Common/common.scss';
import logo from '../../logo.png'
import guardianbg from '../../images/shouhuzhejihua.jpg';
import '../xwcss/bxbase.scss';
import './guardianhome.scss';

import { WhiteSpace, Button } from 'antd-mobile';

import { getUserById } from '../../api/api.js';
import { getWeixinOpenid } from '../../api/weixin.js';
// import jsonProvice from '../../assets/city_code.json';
// import store from '../../store/store.js';



class guardianhome extends React.Component {

    state = {
        getopenid: false,
        userNotExist: false,//用户不存在要提示先去绑定
    }

    // render挂载后 UI渲染完成后调用，且只执行一次
    componentDidMount = () => {
        // store.getState() //
        getWeixinOpenid(this._getUserById)
    }

    _getUserById = (openid) => {
        this.setState({ loading: true })
        getUserById(openid).then(res => {
            const tempdata = res.data.data
            console.log(tempdata);
            if (!tempdata) {
                //用户不存在
                this.setState({
                    getopenid: false,
                })

            } else {
                this.setState({
                    getopenid: true,
                })

            }
        }).catch(err => {
            this.setState({
                getopenid: false,
                userNotExist: false,//用户不存在要提示先去绑定
            })
        })
    }

    onbtn = () => {

        if (this.state.getopenid === true) { //存在
            this.props.history.push('/weixin/guardianproject/guardianproject?code=789&state=')
        } else {
            this.setState({
                userNotExist: true,
            })
        }
    }

    // 、、、、、、、、、、、、提交
    render() {
        return (
            <div className="guardianhome">
                {!this.state.userNotExist && <div>
                <img className='guardianbg' src={guardianbg} alt="" /><div className="guardiananniu" onClick={this.onbtn}></div>
                </div>
                }
                {this.state.userNotExist && <div>
                    <div className="headContent">
                        <img className='logo' src={logo} alt="" />
                        <span>守护者计划</span>
                    </div>
                    <WhiteSpace size="xl" />
                    <span>用户不存在 请先绑定设备</span>
                    <WhiteSpace size="md" />
                    <Button onClick={() => { this.props.history.push('/weixin/BindProduction?code=789&state=') }} className='bindButton'>
                        前往绑定
                        </Button>
                </div>
                }
            </div>
        )
    }
}
export default guardianhome;