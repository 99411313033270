import React from 'react';
import '../Common/common.scss';
import chanpinjieshaozong from '../../images/chanpinjieshaozong.jpg';
import '../xwcss/bxbase.scss';
import './xwProductintroduct.scss'
class xwProductintroduct extends React.Component {
    // 、、、、、、、、、、、、提交
    render() {
        return (
            <div className="chanpinjieshaozong">
                <img src={chanpinjieshaozong} alt="" />
            </div>
        )
    }
}
export default xwProductintroduct;