import React from 'react';
import { Link } from 'react-router-dom';
import './BindProduction.scss';
import { Button, Flex, List, InputItem, Toast, DatePicker, Picker, WhiteSpace, TextareaItem, Icon,Checkbox } from 'antd-mobile';
import logo from '../../logo.png'
import sacnSVG from '../../assets/scan.svg'

import { getUserById, bindEquipment, sendDuanxin } from '../../api/api.js'
import { getWeixinOpenid, chooseImage } from '../../api/weixin.js'

import jsonProvice from '../../assets/city_code.json'
import store from '../../store/store.js'

const genderList = [
    {
        value: 1,
        label: '男'
    },
    {
        value: 2,
        label: '女'
    }]

const regRule = new RegExp(
    /^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9])[0-9]{8}$/
)

let timeIntevalOBJ = null
let timeOut = 60

class BindProduction extends React.Component {
    state = {
        loading: true,
        hasBindBefore: true,//默认为true防止页面闪烁
        hasnameError: false,
        hasphoneError: false,
        hasCodeError: false,
        hasProductError: false,
        buttonLoading: false,
        bindSucess: false,
        name: '',
        phone: '',
        verifyCode: '',
        gender: '',
        birth: '',
        address: '',
        detailaddress: '',
        agree: false,
        productType: '',
        disableGetText: false
    }

    // render挂载后 UI渲染完成后调用，且只执行一次
    componentDidMount = () => {
        getWeixinOpenid(this._getUserById)
    }

    _getUserById = (openid) => {
        this.setState({ loading: true })
        getUserById(openid).then(res => {
            const name = window.location.search.substr(1).split('&')[0].split('=')[0]
            if (name === 'eventKey') {
                const code = window.location.search.substr(1).split('&')[0].split('=')[1]
                setTimeout(() => {
                    this.setState({ productType: code })
                }, 1000);
            }
            const tempdata = res.data.data
            if (!tempdata) {
                //用户不存在
                this.setState({
                    hasBindBefore: false,
                    name: '',
                    phone: '',
                    verifyCode: '',
                    gender: '',
                    birth: '',
                    address: '',
                    detailaddress: '',
                    productType: '',
                    loading: false
                })
            } else {
                this.setState({
                    hasBindBefore: true,
                    name: tempdata.name,
                    phone: tempdata.phone,
                    gender: tempdata.gender === 1 ? '男' : '女',
                    birth: new Date(tempdata.birthDay).toLocaleDateString(),
                    detailaddress: `${tempdata.province} ${tempdata.city} ${tempdata.area} ${tempdata.address}`,
                    productType: '',
                    loading: false
                })
            }
        }).catch(err => {
            this.setState({
                hasBindBefore: false,
                name: '',
                phone: '',
                verifyCode: '',
                gender: '',
                birth: '',
                address: '',
                detailaddress: '',
                productType: '',
                loading: false
            })
        })
    }

    ongetVerifyCode = () => {
        const test = regRule.test(this.state.phone.replace(/\s/g, ''))
        if (!test) {
            this.setState({ hasphoneError: true })
        } else {
            sendDuanxin(this.state.phone.replace(/\s/g, ""));
            this.setState({ disableGetText: true, hasphoneError: false })
            timeIntevalOBJ = setInterval(() => {
                if (timeOut === 0) {
                    clearInterval(timeIntevalOBJ)
                    this.setState({ disableGetText: false })
                    timeOut = 60
                }
                timeOut--
            }, 1000)
        }
    }

    onBindProductionClick = () => {
        const test = regRule.test(this.state.phone.replace(/\s/g, '')) //15712157631
        if (!test ||
            !this.state.name ||
            (!this.state.verifyCode && !this.state.hasBindBefore) || //没有验证码并且是新用户没有绑定
            !this.state.productType) {
            this.setState({
                hasphoneError: !test,
                hasnameError: !this.state.name,
                hasCodeError: !this.state.verifyCode,
                hasProductError: !this.state.productType
            });
        } else {
            this.setState({
                hasphoneError: false,
                hasnameError: false,
                hasCodeError: false,
                hasProductError: false
            });
            const userdate = {
                name: this.state.name,
                phone: this.state.phone.replace(/\s/g, ""),
                ipCode: this.state.hasBindBefore ? '' : this.state.verifyCode,
                gender: this.state.hasBindBefore ? (this.state.gender === '男' ? 1 : 2) : this.state.gender[0],
                birthDay: !this.state.birth ? "" : new Date(this.state.birth).getTime(),
                province: this.state.hasBindBefore ? "" : (!this.state.address[0] ? '' : this.state.address[0]),
                city: this.state.hasBindBefore ? "" : (!this.state.address[1] ? '' : this.state.address[1]),
                area: this.state.hasBindBefore ? "" : (!this.state.address[2] ? '' : this.state.address[2]),
                address: this.state.hasBindBefore ? "" : (this.state.detailaddress),
            }
            this.setState({ buttonLoading: true })
            bindEquipment(store.getState(), this.state.productType, userdate).then(res => {
                this.setState({ buttonLoading: false })
                if (res.data.success) {
                    Toast.success(res.data.message, 1);
                    //绑定成功后弹出两个跳转按钮
                    this.setState({ bindSucess: true })
                }
            }).catch(err => {
                this.setState({ buttonLoading: false })
            })
        }
    }

    onCountinueBindClick = () => {
        this.setState({ bindSucess: false, agree: false });
        this._getUserById(store.getState())
    }

    scanCodeCallBack = (res) => {
        console.log(res)
        let info = !res ? '' : res
        this.setState({ productType: info, loading: false });
    }

    scanCode = () => {
        this.setState({ loading: true });
        chooseImage(this.scanCodeCallBack)
    }

    render() {
        return (
            <div className="BindProduction">

                <div className="headContent">
                    <img className='logo' src={logo} alt="" />
                    <span>绑定新产品</span>
                </div>
                {this.state.loading &&
                    <div className='flexcenter'>
                        <Icon type="loading" size='lg' />
                    </div>}
                {!this.state.bindSucess && !this.state.loading &&
                    <List>
                        <InputItem
                            placeholder="输入姓名"
                            value={this.state.name}
                            error={this.state.hasnameError}
                            onChange={name => this.setState({ name })}
                            editable={!this.state.hasBindBefore}
                        >姓名</InputItem>

                        <WhiteSpace size="md" />
                        <InputItem
                            type="phone"
                            placeholder="输入手机号"
                            error={this.state.hasphoneError}
                            value={this.state.phone}
                            onChange={phone => this.setState({ phone })}
                            editable={!this.state.hasBindBefore}
                        >手机号码</InputItem>

                        {!this.state.hasBindBefore &&
                            (<div>
                                <WhiteSpace size="md" />
                                <Flex>
                                    <Flex.Item>
                                        <InputItem
                                            type="number"
                                            error={this.state.hasCodeError}
                                            placeholder="输入验证码"
                                            value={this.state.verifyCode}
                                            onChange={verifyCode => this.setState({ verifyCode })}
                                        >验证码</InputItem>
                                    </Flex.Item>

                                    <Flex.Item>
                                        <Button size='small' disabled={this.state.disableGetText} onClick={this.ongetVerifyCode}> 获取验证码</Button>
                                    </Flex.Item>
                                </Flex>
                            </div>)}

                        <WhiteSpace size="md" />
                        {this.state.hasBindBefore &&
                            (<InputItem
                                value={this.state.gender}
                                editable={!this.state.hasBindBefore}
                            >性别</InputItem>
                            )}
                        {!this.state.hasBindBefore &&
                            (<Picker cols={1}
                                data={genderList}
                                value={this.state.gender}
                                onOk={gender => this.setState({ gender })} >
                                <List.Item arrow="horizontal">性别</List.Item>
                            </Picker>)}


                        <WhiteSpace size="md" />
                        {this.state.hasBindBefore &&
                            (<InputItem
                                value={this.state.birth}
                                editable={false}
                            >生日</InputItem>)}

                        {!this.state.hasBindBefore &&
                            (<DatePicker
                                mode="date"
                                title="选择"
                                value={this.state.birth}
                                format='YYYY-MM-DD'
                                minDate={new Date(1900, 1, 1, 0, 0, 0)}
                                maxDate={new Date(2030, 12, 31, 23, 59, 59)}
                                onChange={birth => this.setState({ birth })}
                            >
                                <List.Item arrow="horizontal">生日</List.Item>
                            </DatePicker>)}

                        <WhiteSpace size="md" />
                        {this.state.hasBindBefore &&
                            (<TextareaItem
                                title="详细地址"
                                rows={2}
                                value={this.state.detailaddress}
                                editable={false}
                            ></TextareaItem>)}

                        {!this.state.hasBindBefore &&
                            (<div>
                                <Picker
                                    data={jsonProvice}
                                    value={this.state.address}
                                    onOk={(address) => this.setState({ address })}
                                >
                                    <List.Item arrow="horizontal">地区</List.Item>
                                </Picker>
                                <InputItem
                                    placeholder="输入详细地址"
                                    value={this.state.detailaddress}
                                    onChange={detailaddress => this.setState({ detailaddress })}
                                >详细地址</InputItem>
                            </div>)}

                        <WhiteSpace size="md" />
                        <Flex>
                            <Flex.Item>
                                <InputItem
                                    placeholder="请扫描产品机身底部二维码"
                                    value={this.state.productType}
                                    onChange={productType => this.setState({ productType })}
                                    editable={false}
                                    error={this.state.hasProductError}
                                >产品编码</InputItem>
                            </Flex.Item>
                            <Flex.Item>
                                <Button type='ghost' onClick={this.scanCode}>
                                    <img alt='' src={sacnSVG} />
                                </Button>
                            </Flex.Item>
                        </Flex>

                        <WhiteSpace size="md" />

                        {!this.state.hasBindBefore &&
                            <Flex>
                                <Flex.Item>
                                    {/* <Radio class='agreeRadio' value={this.state.agree} onChange={agree => this.setState({ agree })}>同意</Radio> */}
                                    <Checkbox class='agreeRadio' value={this.state.agree} onChange={agree => this.setState({ agree:!this.state.agree})}>同意</Checkbox>
                                </Flex.Item>
                                <Flex.Item>
                                    <Link
                                       to="/weixin/Privacy"
                                       rel="noopener noreferrer"
                                    >{"<法律声明及隐私权政策>"}</Link>
                                </Flex.Item>
                                <Flex.Item>
                                    <Link
                                        to="/weixin/UserRules"
                                        rel="noopener noreferrer"
                                    >{"<星云注册用户服务条款>"}</Link>
                                </Flex.Item>
                            </Flex>
                        }

                        <WhiteSpace size="md" />
                        <Button
                            className={!this.state.agree && !this.state.hasBindBefore ? 'bindButtonDisabled' : 'bindButton'}
                            disabled={!this.state.agree && !this.state.hasBindBefore}
                            loading={this.state.buttonLoading}
                            onClick={this.onBindProductionClick}>绑定</Button>
                        {/* <Button onClick={this.dd}>绑定</Button> */}
                    </List>
                }
                {this.state.bindSucess &&
                    <List>
                        <WhiteSpace size="md" />
                        {/* <Button onClick={this.onCountinueBindClick} className='bindButton'>
                            继续绑定设备
                            </Button> */}
                        <Button onClick={() => { this.props.history.push('/weixin/guardianhome?code=789&state=') }} className='bindButton'>
                            加入守护者计划
                            </Button>
                        <WhiteSpace size="md" />

                        <Button onClick={() => { this.props.history.push('/weixin/MyProduction?code=789&state=') }} className='bindButton'>
                            查看我的设备
                            </Button>
                        <WhiteSpace size="md" />

                    </List>}
            </div >
        );
    }
}


export default BindProduction;
