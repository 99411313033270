import React from 'react';
import '../Common/common.scss';
import fulizhognxin from '../../images/fulizhognxin.jpg';
import '../xwcss/bxbase.scss';
import './xwwelfarecenter.scss'
class xwwelfarecenter extends React.Component {
    // 、、、、、、、、、、、、提交
    render() {
        return (
            <div className="xwwelfarecenter">
                <img src={fulizhognxin} alt="" />
            </div>
        )
    }
}
export default xwwelfarecenter;