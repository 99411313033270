import React from 'react';
import '../Common/common.scss';
import logo from '../../logo.png'
import '../xwcss/bxbase.scss';
import './guardianproject.scss';
import { InputItem, Toast, List, Picker, DatePicker, Button, Icon } from 'antd-mobile';
import { shouhezhejihua, getUserById } from '../../api/api.js';
import { getWeixinOpenid } from '../../api/weixin.js';
import store from '../../store/store.js';


// const zhengjianleixing = [
//     [
//         {
//             label: '身份证',
//             value: '1',
//         },
//         {
//             label: '社保',
//             value: '2',
//         },
//         {
//             label: '居住证',
//             value: '3',
//         },
//     ]
// ];
const bxxingbie = [
    [
        {
            label: '男',
            value: '1',
        },
        {
            label: '女',
            value: '2',
        }
    ]
];

//投保人年龄
const bxnianling = [
    []
];

const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);

const regRule = new RegExp(
    /^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9])[0-9]{8}$/
)
const feizhongwen = new RegExp(  //
    /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
)
class guardianproject extends React.Component {

    state = {
        beibaorenname: '',
        toubaorenphone: '',
        beibaorenphone1: '',
        userId: '',
        beibaoxianrenguanxi: '',
        beibaorennumber: '',
        value2: 0,
        time: now,
        hasnameError: false,
        hasphoneError: false,
        tbzjbmError: false,
        tbzjbmError11: false,
        beibaorennameError: false,
        sValue6Error: false,
        sValue7Error: false,
        sValue8Error: false,
        dpValue: null,
        customChildValue: null,
        visible: false,
        date2: now,
        sValue: '',
        value: '',
        value1: '',
        sValue2: '',
        value3: '',
        sValue4: [],
        value4: '',
        sValue5:'身份证',
        value5: '',
        sValue6: [],
        value6: '',
        sValue7: [],
        value7: '',
        sValue8: [],
        value8: '',
        buttonLoading: false,
        hasBindBefore: true,//
        beibaojingzhi: false,
        loading: false, //加载
        bindSucess: false,
        beibaorenzhiye: ''  //被保人职业

    }
    // render挂载后 UI渲染完成后调用，且只执行一次
    componentDidMount = () => {
        document.body.scrollIntoView();
        var i = 0
        while (i <= 100) {
            bxnianling[0].push({
                label: i + '岁',
                value: i,
            })
            i++;
        }
        getWeixinOpenid(this._getUserById)
    }

    _getUserById = (openid) => {
        this.setState({ loading: true })
        getUserById(openid).then(res => {
           
            const tempdata = res.data.data
            console.log(tempdata)
            sessionStorage.setItem('tempdata1', JSON.stringify(tempdata))
            // console.log(tempdata);
            if (!tempdata) {
                //用户不存在
                this.setState({
                    hasBindBefore: false,
                    loading: false
                })
                // Toast.success('用户不存在', 1);
                this.props.history.push('/weixin/MyProduction?code=789&state=')

            } else {
                this.setState({
                    hasBindBefore: true,
                    loading: false,
                    userId: tempdata.id,
                })
            }
        }).catch(err => {
            this.setState({
                hasBindBefore: false,
            
                loading: false
            })
        })
    }
    // 、、、、、、、、、、、、提交

    onConfirmbaoxian = () => {
        const test = regRule.test(this.state.beibaorenphone1.replace(/\s/g, ''))
        // const test1 = feizhongwen.test(this.state.toubaozhenjianhaoma.replace(/\s+/g, ""))
        const test3 = feizhongwen.test(this.state.beibaorennumber.replace(/\s+/g, ""))
        if (
            !test
            || !test3
            || !this.state.beibaorenname
            || !this.state.beibaorenzhiye
        ) {
            this.setState({
                hasphoneError: !test,
                tbzjbmError11: !test3,
                beibaorennameError: !this.state.beibaorenname,
                sValue8Error: !this.state.beibaorenzhiye,
            });
        } else {
            this.setState({
                hasphoneError: false,
                tbzjbmError: false,
                tbzjbmError11: false,
                beibaorennameError: false,
                sValue6Error: false,
                sValue7Error: false,
                sValue8Error: false,
            });
            if (this.state.sValue7.length <= 0) {
                Toast.success('请选择年龄', 1);
                return false
            }
            if (this.state.sValue6.length <= 0) {
                Toast.success('请选择被保人性别', 1);
                return false
            }
            const userdata = {
                cardType:1,//身份证类型：默认居民身份证
                // userId: this.state.userId,//用户id，既微信用户得id
                relationShip: this.state.toubaorenphone,//与投保人关系
                name: this.state.beibaorenname,//被保人名字
                idCard: this.state.beibaorennumber,//被保人身份证号码
                gender: this.state.sValue6.toString(),//被保人性别
                birthday: new Date(this.state.date2).getTime(),//被保人生日
                age: parseInt(this.state.sValue7.toString()),//被保人年龄
                phone: this.state.beibaorenphone1.replace(/\s/g, ""),//被保人电话
                occupation: this.state.beibaorenzhiye //被保人职业
            }
            this.setState({
                buttonLoading: true,
                loading: true
            });

            shouhezhejihua(store.getState(), userdata).then(res => {  //提交
                console.log(res);
                this.setState({
                    buttonLoading: false,
                    loading: false
                })
                if (res.data.success) {
                    // Toast.success(res.data.message, 1);
                    this.props.history.push('/weixin/Insurancepolicy/Insurancepolicy?code=789&state=')
                }
            }).catch(err => {
                this.setState({
                    buttonLoading: false,
                    loading: false
                })
            })
        }
    }
    render() {
        // const { value } = this.state;
        return (
            <div className="baoxians" id="baoxians">
                <div className="baoxianshouhuzhe">
                    <div className="headContent">
                        <img className='logo' src={logo} alt="" />
                        <span>加入星云守护者计划</span>
                    </div>
                    {this.state.loading &&
                        <div className='flexcenter'>
                            <Icon type="loading" size='lg' />
                        </div>}

                    <div className="baoxianContent">


                        <div className="bxfuxuankuang-title"><div>被保人信息</div></div>
                        <InputItem
                            className="bx-xingming"
                            placeholder="请输入姓名"
                            // cascade={false}
                            value={this.state.beibaorenname}
                            error={this.state.beibaorennameError}
                            // error={this.state.hasnameError}
                            onChange={beibaorenname => this.setState({ beibaorenname })}
                            editable={!this.state.beibaojingzhi}
                        >姓名:</InputItem>


                        <InputItem className="bx-xingming"
                            value={this.state.sValue5}
                            // error={this.state.hasnameError}
                            onChange={sValue5 => this.setState({ sValue5 })}
                            editable={!this.state.hasBindBefore}
                        >证件类型:</InputItem>


                        <InputItem
                            className="bx-xingming"
                            placeholder="请输入证件号码"
                            error={this.state.tbzjbmError11}
                            value={this.state.beibaorennumber}
                            onChange={beibaorennumber => this.setState({ beibaorennumber })}
                        >证件号码:</InputItem>


                        <Picker
                            data={bxxingbie}
                            title="请选择上性别"
                            cascade={false}
                            extra="请选择"
                            error={this.state.sValue6Error}
                            value={this.state.sValue6}
                            onChange={v => this.setState({ sValue6: v })}
                            onOk={v => this.setState({ sValue6: v })}
                            disabled={this.state.beibaojingzhi}
                        // editable={!this.state.beibaojingzhi}
                        >
                            <List.Item arrow="horizontal" className="looo">性别:</List.Item>
                        </Picker>
                        <DatePicker
                            mode="date"
                            title="Select Date"
                            minDate={new Date(1900, 1, 1, 0, 0, 0)}
                            maxDate={new Date(2030, 12, 31, 23, 59, 59)}
                            value={this.state.date2}
                            onChange={date2 => this.setState({ date2 })}
                            disabled={this.state.beibaojingzhi}
                        >
                            <List.Item arrow="horizontal" className="looo">出生日期:</List.Item>
                        </DatePicker>

                        <Picker
                            data={bxnianling}
                            title="请选择年龄"
                            cascade={false}
                            extra="请选择"
                            error={this.state.sValue7Error}
                            value={this.state.sValue7}
                            onChange={v => this.setState({ sValue7: v })}
                            onOk={v => this.setState({ sValue7: v })}
                        >
                            <List.Item arrow="horizontal" className="looo">年龄:</List.Item>
                        </Picker>

                        <InputItem
                            className="bx-xingming"
                            type="phone"
                            placeholder="请输入联系电话"
                            error={this.state.hasphoneError}
                            value={this.state.beibaorenphone1}
                            onChange={beibaorenphone1 => this.setState({ beibaorenphone1 })}
                            editable={!this.state.beibaojingzhi}
                        >联系电话:</InputItem>

                        <InputItem
                            className="bx-xingming"
                            type="text"
                            placeholder="请输入职业"
                            error={this.state.sValue8Error}
                            value={this.state.beibaorenzhiye}
                            onChange={beibaorenzhiye => this.setState({ beibaorenzhiye })}
                        >职业大类:</InputItem>
                        <Button className="bx-btn" loading={this.state.buttonLoading} onClick={this.onConfirmbaoxian}>确定加入</Button>
                    </div>
                </div>
            </div>
        )
    }
}
export default guardianproject;