import React from 'react';
import './AfterSales.scss';
import { Button, List, Picker, WhiteSpace, TextareaItem, Toast, Icon } from 'antd-mobile';
import logo from '../../logo.png'

import store from '../../store/store.js'
import { getBindEqType, getBindEqTypeName, addLeaveMessage, LeaveMessageinfo } from '../../api/api.js'
import { getWeixinOpenid } from '../../api/weixin.js'

const aftersalesType = [
    // {
    //     // value: '预约安装',
    //     label: '预约安装'
    // },
    {
        value: '在线报修',
        label: '在线报修'
    }

]

let productType = []


const visitType = [
    {
        value: '电话回访',
        label: '电话回访'
    },
    {
        value: '人工客服',
        label: '人工客服'
    }
]

class AfterSales extends React.Component {
    state = {
        type1: "",
        type2: "",
        type3: "",
        type4: "",
        message: "",
        userNotExist: false,//用户不存在要提示先去绑定
        loading: true,
        buttonLoading: false,
        applySuccess: false,
        productDetail: [],
        id:'',
        tijiaohtml:'提交',
        chakanwenti:false
    }

    componentDidMount = () => {
        getWeixinOpenid(this._getBindEqType)
    }

    _getBindEqType = (openid) => {
        this.setState({ loading: true })
        getBindEqType(openid).then(res => {
            const tempdata = res.data.data
            console.log(res)
            if (!tempdata || tempdata.totalItems === 0) {
                //没有这个用户
                this.setState({
                    userNotExist: true,
                    loading: false
                })
            } else {
                productType = tempdata.list.map(item => {
                    return {
                        label: item.type,
                        value: item.type,
                    }
                })
                /////////////////////////////////////////////////
                // 留言回显
                LeaveMessageinfo(openid).then(res => {
                    const tempdata = res.data.data
                    console.log(tempdata)
                    if (tempdata) {
                        // 有回显留言
                        this.setState({
                            type1:[tempdata.serverType],
                            type2:[tempdata.equipmentType],
                            productDetail:[{
                                value: tempdata.equipmentModel,
                                label: tempdata.equipmentModel
                            }],
                            type3:[tempdata.equipmentModel],
                            type4:[tempdata.returnType],
                            message:tempdata.leaveMessage,
                            id:tempdata.id,
                            userNotExist: false,
                            loading: false,
                            chakanwenti:true,
                            tijiaohtml:'重新提交',
                        })
                        return false
                    }
                    this.setState({
                        userNotExist: false,
                        loading: false
                    })
                }).catch(err => {
                    // this.setState({
                    //     userNotExist: true,
                    //     loading: false
                    // })
                })
                ////////////////////////////////////////////////
            }
        }).catch(err => {
            this.setState({
                userNotExist: true,
                loading: false
            })
        })
    }

    _getBindEqTypeName = (openid, type) => {
        this.setState({ loading: true, productDetail: [] })
        getBindEqTypeName(openid, type).then(res => {
            const temp = res.data.data.list.map(item => {
                return {
                    label: item.typeName,
                    value: item.typeName,
                }
            })
            this.setState({ loading: false, productDetail: temp })
        }).catch(err => {
            this.setState({ loading: false })
        })
    }

    onSelectType = (type2) => {
        this.setState({ type2: type2 });
        this._getBindEqTypeName(store.getState(), type2[0]);
    }

    onVisitClick = () => {
        // console.log(this.state.tijiaohtml)
        if(this.state.tijiaohtml==='重新提交'){
            this.setState({
                tijiaohtml:'提交', 
                chakanwenti:false,
            })
            return false
        }

        console.log(this.state.type1[0])
        const params = {
            serverType: this.state.type1[0],
            equipmentType: this.state.type2[0],
            equipmentModel: this.state.type3[0],
            returnType: this.state.type4[0],
            leaveMessage: this.state.message,
            id:this.state.id
        }
        this.setState({ buttonLoading: true })
        addLeaveMessage(store.getState(), params).then(res => {
            if (res.data.success) {
                Toast.success(res.data.message, 1);
                this.setState({ applySuccess: true })
            }
            this.setState({ buttonLoading: false })
        }).catch(err => {
            this.setState({ buttonLoading: false })
        })
    }

    onCountinueApplyClick = () => {
        this.setState({
            applySuccess: false,
            type1: "",
            type2: "",
            type3: "",
            type4: "",
            message: "",
        });
        this._getBindEqType(store.getState())
    }

    render() {
        return (
            <div className="AfterSales">
                <div className="headContent">
                    <img className='logo' src={logo} alt="" />
                    <span>售后服务</span>
                </div>
                {this.state.loading &&
                    <div className='flexcenter'>
                        <Icon type="loading" size='lg' />
                    </div>}
                {!this.state.applySuccess && !this.state.userNotExist && !this.state.loading && < List >
                    <WhiteSpace size="md" />
                    <Picker
                        cols={1}
                        data={aftersalesType}
                        disabled={this.state.chakanwenti}
                        value={this.state.type1}
                        onOk={(type1) => this.setState({ type1 })}
                    >
                        <List.Item arrow="horizontal">售后类型</List.Item>
                    </Picker>

                    <WhiteSpace size="md" />
                    <Picker
                        cols={1}
                        data={productType}
                        disabled={this.state.chakanwenti}
                        value={this.state.type2}
                        onOk={this.onSelectType}
                    >
                        <List.Item arrow="horizontal">产品类型</List.Item>
                    </Picker>

                    <WhiteSpace size="md" />
                    <Picker
                        extra={!this.state.type2 ? "请先选择类型" : '请选择'}
                        cols={1}
                        data={this.state.productDetail}
                        value={this.state.type3}
                        onOk={(type3) => this.setState({ type3 })}
                        disabled={ (!this.state.type2 && !this.state.productDetail) || this.state.chakanwenti }
                    >
                        <List.Item arrow="horizontal">产品型号</List.Item>
                    </Picker>

                    <WhiteSpace size="md" />
                    <Picker
                        cols={1}
                        data={visitType}
                        disabled={this.state.chakanwenti}
                        value={this.state.type4}
                        onOk={(type4) => this.setState({ type4 })}
                    >
                        <List.Item arrow="horizontal">回访方式</List.Item>
                    </Picker>

                    <WhiteSpace size="md" />
                    <TextareaItem
                        title="问题描述"
                        placeholder="输入留言"
                        // disabled='false'
                        value={this.state.message}
                        rows={5}
                        disabled={this.state.chakanwenti}
                        count={100}
                        onChange={(message) => this.setState({ message })}
                    >
                    </TextareaItem>

                    <WhiteSpace size="md" />
                    <Button className='bindButton' loading={this.state.buttonLoading} onClick={this.onVisitClick}>{this.state.tijiaohtml}</Button>
                </List>
                }
                {this.state.applySuccess && <List>
                    <WhiteSpace size="md" />
                    <Button onClick={this.onCountinueApplyClick} className='bindButton'>
                        查看提交问题
                            </Button>
                    {/* <Button onClick={() => { this.props.history.push('/weixin/BindProduction?code=789&state=') }} className='bindButton'>
                        前去绑定设备
                            </Button>
                    <Button onClick={() => { this.props.history.push('/weixin/MyProduction?code=789&state=') }} className='bindButton'>
                        查看我的设备
                            </Button> */}

                </List>
                }
                {this.state.userNotExist && <div>

                    <WhiteSpace size="xl" />
                    <span>用户不存在 请先绑定设备</span>
                    <WhiteSpace size="md" />
                    <Button onClick={() => { this.props.history.push('/weixin/BindProduction?code=789&state=') }} className='bindButton'>
                        前往绑定
                        </Button>
                </div>
                }
            </div >
        );
    }
}


export default AfterSales;
