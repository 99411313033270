import React from 'react';
import '../Common/common.scss';
import logo from '../../logo.png';
import dbg from '../../images/dbg.png';
import tianmao from '../../images/tianmao.png';
import jingdong from '../../images/jingdong.png';
import chanpinicon from '../../images/chanpinicon.png';
import oxygenerator from '../../images/oxygenerator.png';
import taobaoicon from '../../images/taobaoicon.png';
// import { WhiteSpace } from 'antd-mobile';
import '../xwcss/bxbase.scss';
import './xwbuyingcenter.scss';
class xwbuyingcenter extends React.Component {
    // 、、、、、、、、、、、、提交
    render() {
        return (
            <div className="xwbuyingcenter">
                <div className="headContent">
                    <img className='logo' src={logo} alt="" />
                </div>
                <div className="dianbeijing">
                    <img className='dbg' src={dbg} alt="" />
                    <a className="taobaozhognchou" href="https://izhongchou.taobao.com/dreamdetail.htm?ut_sk=1.XeNawZPqlQMDAFo76FORxSoE_21380790_1607062161557.DingTalk.windvane&sourceType=other&id=20095297&origin=taobao&suid=98ADFC72-7DE7-4214-9C67-92E9C3A590C8&un=a8b6dcf4dfcba1ec2040bd82ddc2e1ea&share_crt_v=1&cpp=1&shareurl=true&spm=a313p.22.1l8.1212747876278&short_name=h.4UOs7pn&bxsign=scdkQAfvAUc9CBqZltuwV5nK4sRJwQCMIDgR3USPmDUOmWahencHXzg5QcY5R0NxoFjOKfSj7EkhirW55pusTuIboY4XxeB1yULko5_O6SUSwU&app=chrome">啥也不是</a>
                </div>
                <div className="dianpulianjie">
                    <div className="bot-right">
                        <span><img className='tianmao' src={tianmao} alt="" /></span>
                        <span><img className='hyj1' src={oxygenerator} alt="" /></span>
                    </div>
                    <div>
                        <span><img className='jingdong' src={jingdong} alt="" /></span>
                        <span><img className='hyj1' src={oxygenerator} alt="" /></span>
                    </div>
                </div>
                <div className="bgbg"></div>
                <div className="chanpinliebiao">
                    <a href="https://izhongchou.taobao.com/dreamdetail.htm?ut_sk=1.XeNawZPqlQMDAFo76FORxSoE_21380790_1607062161557.DingTalk.windvane&sourceType=other&id=20095297&origin=taobao&suid=98ADFC72-7DE7-4214-9C67-92E9C3A590C8&un=a8b6dcf4dfcba1ec2040bd82ddc2e1ea&share_crt_v=1&cpp=1&shareurl=true&spm=a313p.22.1l8.1212747876278&short_name=h.4UOs7pn&bxsign=scdkQAfvAUc9CBqZltuwV5nK4sRJwQCMIDgR3USPmDUOmWahencHXzg5QcY5R0NxoFjOKfSj7EkhirW55pusTuIboY4XxeB1yULko5_O6SUSwU&app=chrome">
                        <div className="liebiaoxiang">
                            <div className="cptuwen">
                                <i><img className='chanpinicon' src={chanpinicon} alt="" /></i>
                                <span>
                                    <em>星云便携式制氧机</em>
                                    <em><span>￥<span className="price1">2999</span><i>起</i></span><span><img className='taobaoxinhuo' src={taobaoicon} alt="" /></span></em>
                                </span>
                            </div>
                        </div>
                    </a>
                    <div className="xxx"></div>
                    {/* <div className="liebiaoxiang">
                        <div className="cptuwen">
                            <i><img className='chanpinicon' src={chanpinicon} alt="" /></i>
                            <span>
                                <em>星云便携式制氧机</em>
                                <em><span>￥<span className="price1">2999</span><i>起</i></span><span><img className='taobaoxinhuo' src={taobaoicon} alt="" /></span></em>
                            </span>
                        </div>
                    </div>
                    <div className="xxx"></div> */}
                </div>


            </div>
        )
    }
}
export default xwbuyingcenter;